import React from "react";
import styled from "styled-components";
import logofinal from '../../../../images/carbonsmith_logo_white.png';
import logodark from '../../../../images/carbonsmith_logo_black.png';
import { useNavigate } from "react-router-dom";

const HeaderContainer = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 10vh;
  
  @media (max-width: 750px) {
    padding: 0 5%;
  }
  ${props => props.absolute && 'position: absolute; z-index: 1000;'}
`;

const Logo = styled.img`
  width: auto;
  height: 60px;
  object-fit: contain;
  cursor: pointer;
  margin: 0;

  @media (max-width: 750px) {
    height: 30px;
    margin: 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 750px) {
    gap: 6px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1rem;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 750px) {
    font-size: 0.8rem;
    margin-top: 0;
  }
`;

const GetStartedButton = styled.div`
  background-color: white;
  color: #0A3C57;
  border-radius: 20px;
  font-weight: 400;
  font-size: 1rem;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 750px) {
    padding: 2.5px 7px;
    font-size: 0.8rem;
  }
`;

const NewHeader = ({ absolute = false }) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer absolute={absolute}>
      <Logo src="/images/carbonsmith_logo_white.png" alt="Carbonsmith Logo" onClick={() => navigate('/')} />
      <Nav>
        <NavLink href="/">Home</NavLink>
        <NavLink href="/our-team">About</NavLink>
        <NavLink href="/blog">Blog</NavLink>
        <NavLink href="/login">Login</NavLink>
        <GetStartedButton onClick={() => navigate('/')}>
          <a href="#contact-form" style={{ textDecoration: 'none', color: '#0A3C57' }}>
            Get Started
          </a>
        </GetStartedButton>
      </Nav>
    </HeaderContainer>
  );
};

const NewHeaderLight = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer style={{ backgroundColor: 'white', paddingLeft: '5%' }}>
      <Logo src="/images/carbonsmith_logo_black.png" alt="Carbonsmith Logo" onClick={() => navigate('/')} />
      <Nav>
        <NavLink href="/" style={{ color: '#0A3C57' }}>Home</NavLink>
        <NavLink href="/our-team" style={{ color: '#0A3C57' }}>About</NavLink>
        <NavLink href="/blog" style={{ color: '#0A3C57' }}>Blog</NavLink>
        <NavLink href="/login" style={{ color: '#0A3C57' }}>Login</NavLink>
        <GetStartedButton style={{ backgroundColor: '#0A3C57', color: 'white' }} onClick={() => navigate('/')}>
          <a href="#contact-form" style={{ textDecoration: 'none', color: 'white' }}>
            Get Started
          </a>
        </GetStartedButton>
      </Nav>
    </HeaderContainer>
  );
};

export { NewHeader, NewHeaderLight };
